import React from "react";
import { Document, Page, Text } from "@react-pdf/renderer";
import styles from "./StylesPDF";
import EducationPDF from "./EducationPDF";
import WorkExperiencePDF from "./WorkExperiencePDF";
import ProjectsPDF from "./ProjectsPDF";
import LanguagesPDF from "./LanguagesPDF";
import CertificatesPDF from "./CertificatesPDF";

const FullDocument = (params) => (
  <Document>
    <Page size="A4" style={styles.body}>
      <Text style={styles.title}>
        {params.data.person.full_name[params.lang]}
      </Text>
      <Text style={styles.description}>
        {params.data.pdf.description[params.lang]}
      </Text>

      <Text style={styles.subtitle}>
        {params.data.work_experience.section_title[params.lang]}
      </Text>
      <WorkExperiencePDF lang={params.lang} data={params.data} />

      <Text style={styles.subtitle}>
        {params.data.certificates.section_title[params.lang]}
      </Text>
      <CertificatesPDF lang={params.lang} data={params.data} />

      <Text style={styles.subtitle}>
        {params.data.projects.section_title[params.lang]}
      </Text>
      <ProjectsPDF lang={params.lang} data={params.data} />

      <Text style={styles.subtitle}>
        {params.data.education.section_title[params.lang]}
      </Text>
      <EducationPDF lang={params.lang} data={params.data} />

      <Text style={styles.subtitle}>
        {params.data.languages.section_title[params.lang]}
      </Text>
      <LanguagesPDF lang={params.lang} data={params.data} />

      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  </Document>
);

export default FullDocument;
