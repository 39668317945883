import { StyleSheet, Font } from "@react-pdf/renderer";
import fontLight from "../../assets/fonts/roboto-light-webfont.ttf";
import fontBold from "../../assets/fonts/roboto-bold-webfont.ttf";

const styles = StyleSheet.create({
  body: {
    paddingTop: 15,
    paddingBottom: 25,
    paddingHorizontal: 15,
    fontFamily: "Roboto",
  },
  title: {
    fontSize: 18,
    fontStyle: "bold",
    textAlign: "center",
  },
  description: {
    marginTop: 5,
    marginBottom: 10,
    fontSize: 11,
    textAlign: "center",
  },
  subtitle: {
    margin: 10,
    marginBottom: 10,
    fontSize: 14,
    fontStyle: "bold",
    textAlign: "center",
    textDecoration: "underline",
  },
  header: {
    margin: 7.5,
    fontSize: 12,
    fontStyle: "bold",
  },
  text: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 2.5,
    marginBottom: 2.5,
    fontSize: 11,
    textAlign: "justify",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

Font.register({
  family: "Roboto",
  fonts: [{ src: fontLight }, { src: fontBold, fontStyle: "bold" }],
});

export default styles;
