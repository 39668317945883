import React, { useEffect, useState } from "react";
import FlagHR from "../../../assets/images/HR.svg";
import FlagEN from "../../../assets/images/GB.svg";
import { useCookies } from "react-cookie";
import { useData } from "../../../services/DataProvider";

const ChangeLanguage = () => {
  const [cookies, setCookie] = useCookies(["lang"]);
  const [lang, setLang] = useState(cookies.lang || "hr");

  const { data } = useData();

  useEffect(() => {
    if (!cookies.lang) {
      setCookie("lang", lang, { path: "/", maxAge: 604800 }); // 7 days
    }
  }, [lang, setCookie, cookies.lang]);

  const toggleLang = () => {
    const newLang = lang === "hr" ? "en" : "hr";
    setLang(newLang);
    setCookie("lang", newLang, { path: "/", maxAge: 604800 }); // 7 days
  };

  return (
    <>
      <p
        className="cursor-pointer hover-opacity unselectable"
        onClick={() => toggleLang()}
      >
        <img
          className="margin-right"
          src={lang === "hr" ? FlagEN : FlagHR}
          alt="Switch language"
          style={{ width: "23px" }}
        />
        {data.person.language[lang]}
      </p>
    </>
  );
};

export default ChangeLanguage;
