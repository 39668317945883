import React from "react";
import { useData } from "../../services/DataProvider";

const Skills = ({ lang }) => {
  const { data } = useData();

  return (
    <div className="container card white">
      <h2 className="text-grey padding-16v-10h">
        <i className="fa-solid fa-lightbulb fa-fw margin-right text-primary"></i>
        {data.skills.section_title[lang]}
      </h2>

      <div className="container">
        <div className="margin-top-minus-15 row">
          <div className="column left">
            <p>{data.skills.all_skills.skill_1[lang]}</p>
            <div className="light-grey round-xlarge small">
              <div
                className="center round-xlarge primary"
                style={{
                  width: data.skills.all_skills.skill_1.percentage,
                }}
              >
                {data.skills.all_skills.skill_1.percentage}
              </div>
            </div>

            <p>{data.skills.all_skills.skill_3[lang]}</p>
            <div className="light-grey round-xlarge small">
              <div
                className="center round-xlarge primary"
                style={{
                  width: data.skills.all_skills.skill_3.percentage,
                }}
              >
                {data.skills.all_skills.skill_3.percentage}
              </div>
            </div>

            <p>{data.skills.all_skills.skill_5[lang]}</p>
            <div className="light-grey round-xlarge small">
              <div
                className="center round-xlarge primary"
                style={{
                  width: data.skills.all_skills.skill_5.percentage,
                }}
              >
                {data.skills.all_skills.skill_5.percentage}
              </div>
            </div>

            <p>{data.skills.all_skills.skill_7[lang]}</p>
            <div className="light-grey round-xlarge small">
              <div
                className="center round-xlarge primary"
                style={{
                  width: data.skills.all_skills.skill_7.percentage,
                }}
              >
                {data.skills.all_skills.skill_7.percentage}
              </div>
            </div>

            <p>{data.skills.all_skills.skill_9[lang]}</p>
            <div className="light-grey round-xlarge small">
              <div
                className="center round-xlarge primary"
                style={{
                  width: data.skills.all_skills.skill_9.percentage,
                }}
              >
                {data.skills.all_skills.skill_9.percentage}
              </div>
            </div>
          </div>

          <div className="column right">
            <p>{data.skills.all_skills.skill_2[lang]}</p>
            <div className="light-grey round-xlarge small">
              <div
                className="center round-xlarge primary"
                style={{
                  width: data.skills.all_skills.skill_2.percentage,
                }}
              >
                {data.skills.all_skills.skill_2.percentage}
              </div>
            </div>

            <p>{data.skills.all_skills.skill_4[lang]}</p>
            <div className="light-grey round-xlarge small">
              <div
                className="center round-xlarge primary"
                style={{
                  width: data.skills.all_skills.skill_4.percentage,
                }}
              >
                {data.skills.all_skills.skill_4.percentage}
              </div>
            </div>

            <p>{data.skills.all_skills.skill_6[lang]}</p>
            <div className="light-grey round-xlarge small">
              <div
                className="center round-xlarge primary"
                style={{
                  width: data.skills.all_skills.skill_6.percentage,
                }}
              >
                {data.skills.all_skills.skill_6.percentage}
              </div>
            </div>

            <p>{data.skills.all_skills.skill_8[lang]}</p>
            <div className="light-grey round-xlarge small">
              <div
                className="center round-xlarge primary"
                style={{
                  width: data.skills.all_skills.skill_8.percentage,
                }}
              >
                {data.skills.all_skills.skill_8.percentage}
              </div>
            </div>

            <p>{data.skills.all_skills.skill_10[lang]}</p>
            <div className="light-grey round-xlarge small">
              <div
                className="center round-xlarge primary"
                style={{
                  width: data.skills.all_skills.skill_10.percentage,
                }}
              >
                {data.skills.all_skills.skill_10.percentage}
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />
    </div>
  );
};

export default Skills;
