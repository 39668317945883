import React from "react";
import "./LoadingSpinner.css";

const LoadingSpinner = ({ error }) => {
  return (
    <div className="loading-indicator">
      {error ? (
        <div className="error-message">
          <div className="error-icon">❌</div>
          <p className="error-text">
            Failed to retrieve data, refresh the page to try again.
          </p>
        </div>
      ) : (
        <div className="spinner"></div>
      )}
    </div>
  );
};

export default LoadingSpinner;
