import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import Person from "../components/ResumeItems/Person/Person";
import Education from "../components/ResumeItems/Education";
import Languages from "../components/ResumeItems/Languages";
import Skills from "../components/ResumeItems/Skills";
import Experience from "../components/ResumeItems/Experience";
import Projects from "../components/ResumeItems/Projects";
import Footer from "../components/Footer";
import Certificates from "../components/ResumeItems/Certificates";
import CookieConsent from "../components/CookieConsent";

const Resume = () => {
  const [cookies] = useCookies(["lang"]);
  const [lang, setLang] = useState(cookies.lang || "hr");

  useEffect(() => {
    setLang(cookies.lang);
  }, [cookies.lang]);

  return (
    <body className="light-grey">
      <div className="content margin-top" style={{ maxWidth: "1400px" }}>
        <div className="row-padding">
          <div className="onethird">
            <>
              <Person lang={lang} />
            </>

            <br />

            <>
              <Education lang={lang} />
            </>

            <br />

            <>
              <Skills lang={lang} />
            </>

            <br />

            <>
              <Languages lang={lang} />
            </>
          </div>

          <div className="twothird">
            <>
              <Experience lang={lang} />
            </>

            <br />

            <>
              <Projects lang={lang} />
            </>

            <br />

            <>
              <Certificates lang={lang} />
            </>
          </div>
        </div>
      </div>

      <footer className="container primary center margin-top">
        <>
          <Footer lang={lang} />
        </>
      </footer>

      <CookieConsent lang={lang} />
    </body>
  );
};

export default Resume;
