import React from "react";
import { useData } from "../../services/DataProvider";

const Education = ({ lang }) => {
  const { data } = useData();

  const obj = Object.entries(data.education.schools).map(([key, value]) => ({
    key,
    value,
  }));

  return (
    <div className="container card white">
      <h2 className="text-grey padding-16v-10h">
        <i className="fa fa-book fa-fw margin-right text-primary"></i>
        {data.education.section_title[lang]}
      </h2>

      <div className="container margin-top-minus-7-5">
        {obj.map((item) => (
          <>
            <h5 className="opacity">
              <b key={item.id}>{item.value.name[lang]}</b>
            </h5>

            <h6 className="text-primary" key={item.id}>
              <i className="fa-regular fa-calendar-days fa-fw margin-right"></i>
              {item.value.year.from[lang]} -{" "}
              <span
                className={
                  item.value.year.to.en === "Current" ? "tag primary round" : ""
                }
              >
                {item.value.year.to[lang]}
              </span>
            </h6>

            <p key={item.id}>
              <b>{item.value.major_1[lang]}</b>
              {item.value.major_2[lang]}
            </p>

            <a
              key={item.id}
              className="hidden-link text-black cursor-pointer hover-opacity"
              target="_blank"
              rel="noopener noreferrer"
              href={item.value.thesis_link}
            >
              {item.value.thesis_1[lang]}
            </a>

            <p key={item.id}>{item.value.thesis_2[lang]}</p>
            <hr />
          </>
        ))}
      </div>
    </div>
  );
};

export default Education;
