import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Resume from "./pages/Resume";
import LoadingSpinner from "./components/LoadingSpinner/LoadingSpinner";
import { useData } from "./services/DataProvider";
import "./App.css";

function App() {
  const { loading, error } = useData();

  if (loading || error) {
    return <LoadingSpinner error={error} />;
  }

  return (
    <Router>
      <Routes>
        <Route path="*" element={<Resume />} />
      </Routes>
    </Router>
  );
}

export default App;
