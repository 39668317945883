import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useData } from "../services/DataProvider";

const CookieConsent = ({ lang }) => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const [showPopup, setShowPopup] = useState(!cookies.cookieConsent);

  const { data } = useData();

  const handleAccept = () => {
    setCookie("cookieConsent", true, { path: "/", maxAge: 604800 }); // 7 days
    setShowPopup(false);
  };

  useEffect(() => {
    if (cookies.cookieConsent) {
      setShowPopup(false);
    }
  }, [cookies.cookieConsent]);

  if (!showPopup) return null;

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "rgba(0,0,0,0.8)",
        color: "white",
        padding: "10px",
        textAlign: "center",
      }}
    >
      <p>
        {data.cookies.consent.text[lang]}
        <button
          onClick={handleAccept}
          style={{
            marginLeft: "10px",
            padding: "5px 10px",
            backgroundColor: "#2196f3",
            border: "none",
            borderRadius: "4px",
            color: "white",
            cursor: "pointer",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            height: "30px",
          }}
        >
          {data.cookies.consent.button[lang]}
        </button>
      </p>
    </div>
  );
};

export default CookieConsent;
