import React from "react";
import { Text } from "@react-pdf/renderer";
import styles from "./StylesPDF";

const WorkExperiencePDF = (params) => {
  const obj = Object.entries(params.data.work_experience.experiences).map(
    ([key, value]) => ({
      key,
      value,
    })
  );

  return (
    <>
      {obj.map((item) => (
        <>
          <Text style={styles.header} key={item.id}>
            {item.value.title[params.lang]} (
            {item.value.duration.from[params.lang]} -{" "}
            {item.value.duration.to[params.lang]})
          </Text>
          <Text style={styles.text} key={item.id}>
            {item.value.description_1[params.lang]}
          </Text>
          <Text style={styles.text} key={item.id}>
            {item.value.description_2[params.lang]}
          </Text>
          <Text style={styles.text} key={item.id}>
            {item.value.description_3[params.lang]}
          </Text>
        </>
      ))}
    </>
  );
};

export default WorkExperiencePDF;
