import React from "react";
import FullDocument from "../../PDF/FullDocument";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useData } from "../../../services/DataProvider";

const Download = ({ lang }) => {
  const { data } = useData();

  return (
    <>
      <PDFDownloadLink
        className="hidden-link text-grey unselectable"
        document={<FullDocument lang={lang} data={data} />}
        fileName={"KarloHabdija-CV-" + lang + ".pdf"}
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            <p className="cursor-not-allowed">
              {data.person.pdf.loading[lang]}
            </p>
          ) : error ? (
            <p className="cursor-not-allowed text-error">
              {data.person.pdf.error[lang]}
            </p>
          ) : (
            <p className="cursor-pointer hover-opacity">
              <i className="fa fa-download fa-fw margin-right large text-primary"></i>
              {data.person.pdf.download[lang]}
            </p>
          )
        }
      </PDFDownloadLink>
    </>
  );
};

export default Download;
