import React from "react";
import { useData } from "../../services/DataProvider";

const Languages = ({ lang }) => {
  const { data } = useData();

  return (
    <div className="container card white margin-bottom">
      <h2 className="text-grey padding-16v-10h">
        <i className="fa fa-globe fa-fw margin-right text-primary"></i>
        {data.languages.section_title[lang]}
      </h2>

      <div className="container">
        <div className="margin-top-minus-15">
          <p>{data.languages.all_languages.language_main[lang]}</p>
          <div className="light-grey round-xlarge small">
            <div
              className="center round-xlarge primary"
              style={{
                width: data.languages.all_languages.language_main.percentage,
              }}
            >
              {data.languages.all_languages.language_main.percentage}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="column left">
            <p>{data.languages.all_languages.language_1[lang]}</p>
            <div className="light-grey round-xlarge small">
              <div
                className="center round-xlarge primary"
                style={{
                  width: data.languages.all_languages.language_1.percentage,
                }}
              >
                {data.languages.all_languages.language_1.percentage}
              </div>
            </div>

            <p>{data.languages.all_languages.language_3[lang]}</p>
            <div className="light-grey round-xlarge small">
              <div
                className="center round-xlarge primary"
                style={{
                  width: data.languages.all_languages.language_3.percentage,
                }}
              >
                {data.languages.all_languages.language_3.percentage}
              </div>
            </div>
          </div>

          <div className="column right">
            <p>{data.languages.all_languages.language_2[lang]}</p>
            <div className="light-grey round-xlarge small">
              <div
                className="center round-xlarge primary"
                style={{
                  width: data.languages.all_languages.language_2.percentage,
                }}
              >
                {data.languages.all_languages.language_2.percentage}
              </div>
            </div>

            <p>{data.languages.all_languages.language_4[lang]}</p>
            <div className="light-grey round-xlarge small">
              <div
                className="center round-xlarge primary"
                style={{
                  width: data.languages.all_languages.language_4.percentage,
                }}
              >
                {data.languages.all_languages.language_4.percentage}
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />
    </div>
  );
};

export default Languages;
