import React from "react";
import { Text } from "@react-pdf/renderer";
import styles from "./StylesPDF";

const EducationPDF = (params) => {
  const obj = Object.entries(params.data.education.schools).map(
    ([key, value]) => ({
      key,
      value,
    })
  );

  return (
    <>
      {obj.map((item) => (
        <>
          <Text style={styles.header} key={item.id}>
            {item.value.name[params.lang]} ({item.value.year.from[params.lang]}{" "}
            - {item.value.year.to[params.lang]})
          </Text>
          <Text style={styles.text} key={item.id}>
            {item.value.major_1[params.lang]}
            {item.value.major_2[params.lang]}
          </Text>
          <Text style={styles.text} key={item.id}>
            {item.value.thesis_1[params.lang]}
          </Text>
          <Text style={styles.text} key={item.id}>
            {item.value.thesis_2[params.lang]}
          </Text>
        </>
      ))}
    </>
  );
};

export default EducationPDF;
