import React from "react";
import Download from "./Download";
import ChangeLanguage from "./ChangeLanguage";
import { useData } from "../../../services/DataProvider";

const Person = ({ lang }) => {
  const { data } = useData();

  return (
    <div className="white text-grey card">
      <div>
        <img src="images/avatar.jpg" alt="Avatar" style={{ width: "100%" }} />
      </div>
      <div className="container">
        <h2>{data.person.full_name[lang]}</h2>

        <p>
          <a
            className="hidden-link text-grey hover-opacity"
            href="https://github.com/khabdija"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa fa-briefcase fa-fw margin-right large text-primary"></i>
            {data.person.profession[lang]}
          </a>
        </p>

        <p>
          <a
            className="hidden-link text-grey hover-opacity"
            href="https://maps.app.goo.gl/Vk3wFR1r2hqHvsKa7"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa fa-map-marker fa-fw margin-right large text-primary"></i>
            {data.person.location[lang]}
          </a>
        </p>

        <p>
          <a
            className="hidden-link text-grey hover-opacity"
            href={"mailto:" + data.person.email[lang]}
          >
            <i className="fa fa-envelope fa-fw margin-right large text-primary"></i>
            {data.person.email[lang]}
          </a>
        </p>

        <hr style={{ marginBottom: 0 }} />

        <div className="row align-row-center">
          <Download lang={lang} />
          <ChangeLanguage />
        </div>

        <hr style={{ marginTop: 0 }} />
      </div>
    </div>
  );
};

export default Person;
