import React from "react";
import { Text } from "@react-pdf/renderer";
import styles from "./StylesPDF";

const LanguagesPDF = (params) => {
  return (
    <>
      <Text style={styles.header}>
        {params.data.pdf.languages.native[params.lang]}
      </Text>
      <Text style={styles.text}>
        {params.data.languages.all_languages.language_main[params.lang]}
      </Text>

      <Text style={styles.header}>
        {params.data.pdf.languages.fullprofessional[params.lang]}
      </Text>
      <Text style={styles.text}>
        {params.data.languages.all_languages.language_1[params.lang]},{" "}
        {params.data.languages.all_languages.language_2[params.lang]}
      </Text>

      <Text style={styles.header}>
        {params.data.pdf.languages.limited[params.lang]}
      </Text>
      <Text style={styles.text}>
        {params.data.languages.all_languages.language_3[params.lang]},{" "}
        {params.data.languages.all_languages.language_4[params.lang]}
      </Text>
    </>
  );
};

export default LanguagesPDF;
