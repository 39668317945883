import React from "react";
import { useData } from "../../services/DataProvider";

const Certificates = ({ lang }) => {
  const { data } = useData();

  const obj = Object.entries(data.certificates.all_certs).map(
    ([key, value]) => ({
      key,
      value,
    })
  );

  return (
    <div className="container card white margin-bottom">
      <h2 className="text-grey padding-16v-10h">
        <i className="fa fa-pen-to-square fa-fw margin-right xxlarge text-primary"></i>
        {data.certificates.section_title[lang]}
      </h2>

      <div className="container margin-top-minus-7-5">
        {obj.map((item) => (
          <>
            <h5 className="opacity">
              <b key={item.id}>{item.value.title[lang]}</b>
            </h5>
            <h6 className="text-primary" key={item.id}>
              <i className="fa-regular fa-calendar-days fa-fw margin-right"></i>
              {item.value.duration.from[lang]} -{" "}
              <span
                className={
                  item.value.duration.to.en === "Current"
                    ? "tag primary round"
                    : ""
                }
              >
                {item.value.duration.to[lang]}
              </span>
            </h6>
            <p key={item.id}>
              {item.value.link.length > 0 ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.value.link}
                  className="cursor-pointer hover-opacity hidden-link text-black"
                >
                  {item.value.description_1[lang]}
                </a>
              ) : (
                <>{item.value.description_1[lang]}</>
              )}
            </p>
            <p key={item.id}>{item.value.description_2[lang]}</p>
            <p key={item.id}>{item.value.description_3[lang]}</p>
            <hr />
          </>
        ))}
      </div>
    </div>
  );
};

export default Certificates;
