import React from "react";
import { useData } from "../services/DataProvider";

const Footer = ({ lang }) => {
  const { data } = useData();

  return (
    <>
      <p>{data.footer.social_media.section_title[lang]}</p>

      <a
        target="_blank"
        rel="noopener noreferrer"
        href={data.footer.social_media.links.linkedin}
      >
        <i className="fa-brands fa-linkedin-in hover-opacity text-white padding-5-h"></i>
      </a>

      <a
        target="_blank"
        rel="noopener noreferrer"
        href={data.footer.social_media.links.twitter}
      >
        <i className="fa-brands fa-x-twitter hover-opacity text-white padding-5-h"></i>
      </a>

      <a
        target="_blank"
        rel="noopener noreferrer"
        href={data.footer.social_media.links.facebook}
      >
        <i className="fa-brands fa-facebook hover-opacity text-white padding-5-h"></i>
      </a>

      <a
        target="_blank"
        rel="noopener noreferrer"
        href={data.footer.social_media.links.instagram}
      >
        <i className="fa-brands fa-instagram hover-opacity text-white padding-5-h"></i>
      </a>

      <div className="margin-bottom">
        <p style={{ fontSize: 13 }}>
          {data.footer.credits_1.section_title[lang]}
          <a
            className="text-white hover-opacity"
            target="_blank"
            rel="noopener noreferrer"
            href={data.footer.credits_1.link}
          >
            {data.footer.credits_1.company[lang]}
          </a>

          {data.footer.credits_2.section_title[lang]}
          <a
            className="text-white hover-opacity"
            target="_blank"
            rel="noopener noreferrer"
            href={data.footer.credits_2.link}
          >
            {data.footer.credits_2.company[lang]}
          </a>
        </p>
      </div>
    </>
  );
};

export default Footer;
